/* eslint-disable default-case */
import { Autocomplete, Button, TextField } from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';
import Arrows from './arrows.js'

function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/4');
	}
	return (
		<div className='page'>
			<div className='page-title'>Выберите диаметр диска</div>
			<div className='autocomplete-wrapper'>
				<Autocomplete
				multiple
				options={[10, 11, 12, 13, 14, 15, 16, 17, 18]}
				renderInput={(params) => (
					<TextField
					  {...params}
					  variant="standard"
					/>
				  )}>
					
				</Autocomplete>
			</div>
			<Arrows back='/2' for='/4'></Arrows>
		</div>
	);
}

export default FirstPage;
