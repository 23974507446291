/* eslint-disable default-case */
import { Button } from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';

function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/2');
	}
	return (
		<div className='page'>
			<div className='page-title'>Выберите тип ТС</div>
			<div className='page-buttons'>
				<Button variant='contained' onClick={handleClick}>Легковой</Button>
				<Button variant='contained' onClick={handleClick}>Легкогрузовой</Button>
				<Button variant='contained' onClick={handleClick}>Грузовой</Button>
				<Button variant='contained' onClick={handleClick}>Спецтехника</Button>
			</div>
		</div>
	);
}

export default FirstPage;
