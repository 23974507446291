/* eslint-disable default-case */
import {
	Autocomplete,
	Button,
	TextField,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';
import Arrows from './arrows.js'
function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/12');
	}
	return (
		<div className='page'>
			<div className='page-title'>Выберите тип и количество грибка</div>
			<div className='page-choose'>
					<Autocomplete
						options={[
							'Грибок 1',
							'Грибок 2',
							'Грибок 3',
						]}
						renderInput={(params) => (
							<TextField {...params} variant='standard' />
						)}
					></Autocomplete>


					<TextField type='number' label='Количество' />

			</div>
			<Arrows back='/10' for='/12'></Arrows>
		</div>
	);
}

export default FirstPage;
