import './App.css';
import FirstPage from './FirstPage.js';
import SecondPage from './SecondPage.js';
import ThirdPage from './ThirdPage.js';
import FourthPage from './FourthPage.js';
import FifthPage from './FifthPage.js';
import SixPage from './SixPage.js';
import SevenPage from './SevenPage.js';
import EightPage from './EightPage.js';
import NinePage from './NinePage.js';
import TenPage from './TenPage.js';
import ElevenPage from './ElevenPage.js';
import TwelvePage from './TwelvePage.js';
import ThirteenPage from './ThirteenPage.js';
import FourteenPage from './FourteenPage.js';
import FifteenPage from './FifteenPage.js';
import EndPage from './endPage';
import head from './head.png';
import { BrowserRouter, Routes, Route, Outlet, Link } from 'react-router-dom';
function App() {
	return (
		<BrowserRouter>
			<div className='App'>
				<div className='App-header'>
					<img src={head} alt=''></img>
				</div>

				<Routes>
					<Route path='/' element={<Layout />}>
						<Route path='/' element={<FirstPage />}></Route>
						<Route path='/2' element={<SecondPage />}></Route>
						<Route path='/3' element={<ThirdPage />}></Route>
						<Route path='/4' element={<FourthPage />}></Route>
						<Route path='/5' element={<FifthPage />}></Route>
						<Route path='/6' element={<SixPage />}></Route>
						<Route path='/7' element={<SevenPage />}></Route>
						<Route path='/8' element={<EightPage />}></Route>
						<Route path='/9' element={<NinePage />}></Route>
						<Route path='/10' element={<TenPage />}></Route>
						<Route path='/11' element={<ElevenPage />}></Route>
						<Route path='/12' element={<TwelvePage />}></Route>
						<Route path='/13' element={<ThirteenPage />}></Route>
						<Route path='/14' element={<FourteenPage />}></Route>
						<Route path='/15' element={<FifteenPage />}></Route>
						<Route path='/end' element={<EndPage />}></Route>
					</Route>
				</Routes>
			</div>
		</BrowserRouter>
	);
}
function Layout() {
	return <Outlet />;
}

export default App;
