/* eslint-disable default-case */
import {
	Autocomplete,
	Button,
	TextField,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';
import Arrows from './arrows.js'
function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/6');
	}
	return (
		<div className='page'>
			<div className='page-title'>Монтаж и демонтаж шины делали?</div>
			<div className='page-checklist'>
				<FormGroup>
					<FormControlLabel control={<Switch />} label='Монтаж шины' />
					<FormControlLabel control={<Switch />} label='Демонтаж шины' />
				</FormGroup>
			</div>
			<Arrows back='/4' for='/6'></Arrows>
		</div>
	);
}

export default FirstPage;
