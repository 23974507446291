/* eslint-disable default-case */
import {
	Autocomplete,
	Button,
	TextField,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';
import Arrows from './arrows.js'
function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/7');
	}
	return (
		<div className='page'>
			<div className='page-title'>Колесо балансировали?</div>
			<div className='page-checklist'>
				<FormGroup>
					<FormControlLabel control={<Switch />} label='Балансировали колесо' />
				</FormGroup>
			</div>
			<Arrows back='/5' for='/7'></Arrows>
		</div>
	);
}

export default FirstPage;
