/* eslint-disable default-case */
import {
	Autocomplete,
	Button,
	TextField,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';
import Arrows from './arrows.js'
function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/13');
	}
	return (
		<div className='page'>
			<div className='page-title'>Горячую/Холодную вулканизацию делали?</div>
			<div className='page-checklist'>
				<FormGroup>
					<FormControlLabel control={<Switch />} label='Горячая вулканизация' />
					<FormControlLabel
						control={<Switch />}
						label='Холодная вулканизация'
					/>
				</FormGroup>
			</div>
			<TextField
				id='outlined-number'
				label='Количество циклов'
				type='number'
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<Arrows back='/11' for='/13'></Arrows>
		</div>
	);
}

export default FirstPage;
