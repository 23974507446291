/* eslint-disable default-case */
import {
	Autocomplete,
	Button,
	TextField,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';
import Arrows from './arrows.js'
function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/end');
	}
	return (
		<div className='page'>
			<div className='page-title'>Дополнительные услуги</div>
			TBA
			<div className='page-buttons'>
				<Button variant='contained' onClick={handleClick}>Конец</Button>
			</div>
			<Arrows back='/14'></Arrows>
		</div>
	);
}

export default FirstPage;
