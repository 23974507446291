/* eslint-disable default-case */
import {
	Autocomplete,
	Button,
	TextField,
	InputAdornment,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useState } from 'react';
import './page.css';
import { useNavigate } from 'react-router-dom';
import g50 from './gruz/50.svg'
import g75 from './gruz/75.svg'
import g100 from './gruz/100.svg'
import g150 from './gruz/150.svg'
import g200 from './gruz/200.svg'
import g250 from './gruz/250.svg'
import g300 from './gruz/300.svg'
import g350 from './gruz/350.svg'
import g400 from './gruz/400.svg'
import g500 from './gruz/500.svg'
import Arrows from './arrows.js'
function FirstPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/8');
	}
	return (
		<div className='page'>
			<div className='page-title'>
				Выберите тип и количество балансировочных грузиков?
			</div>
			<div className='page-two-col'>
				<div className='page-two-col__left'>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g50} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g75} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g100} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g150} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g200} alt=''></img></InputAdornment>
							),
						}}
					/>
				</div>
				<div className='page-two-col__right'>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g250} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g300} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g350} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g400} alt=''></img></InputAdornment>
							),
						}}
					/>
					<TextField
						type='number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'><img src={g500} alt=''></img></InputAdornment>
							),
						}}
					/>
				</div>
			</div>
			<Arrows back='/6' for='/8'></Arrows>
		</div>
	);
}

export default FirstPage;
