import './page.css';
import {
	Autocomplete,
	Button,
	TextField,
	FormGroup,
	FormControlLabel,
	Switch,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
function EndPage(props) {
	let history = useNavigate();
	function handleClick() {
		history('/');
	}
	return (
		<div className='Page'>
			<div className='endPage-title'>КОНЕЦ</div>
			<div className='page-buttons'>
				<Button variant='contained' onClick={handleClick}>
					В НАЧАЛО
				</Button>
			</div>

			{/* {props.data.map((k)=>{
                if (k.value)
                return(
                    <div className="endPage-el">
                        <div className="endPage-el__title">{k.title}</div>
                        <div>{k.value}</div>
                    </div>
                )
            })} */}
		</div>
	);
}

export default EndPage;
