import { Button } from '@mui/material';
import './page.css';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';

function Arrows(props) {
	let history = useNavigate();
    const handleBack = () => {
        history(props.back)
    }
    const handleFor = () => {
        history(props.for)
    }
	let backButton;
	let forButton;
	if (props.back) {
		backButton = (
			<Button
				className='left'
				variant='contained'
				onClick={handleBack}
				startIcon={<ArrowBackIcon />}
			>
				Назад
			</Button>
		);
	}
	if (props.for) {
		forButton = (
			<Button
				className='right'
				variant='contained'
				onClick={handleFor}
				endIcon={<ArrowForwardIcon />}
			>
				Далее
			</Button>
		);
	}
	return (
		<div className='page-arrows'>
			{backButton}
			{forButton}
		</div>
	);
}

export default Arrows;
